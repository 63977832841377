<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="xyz">
				<h4 class="p-m-2">Vencidos</h4>
				<div class="p-field p-m-1">
					<label for="id_moneda" class="p-m-1">Moneda</label>
					<Dropdown v-model="id_moneda" :options="lkpMonedas" optionLabel="moneda" optionValue="id_moneda" @change="refrescarVista" placeholder="Seleccionar"/>					
				</div>						
				<Toast/>
		<TabView :activeIndex="activeTabIndex" @tab-click="onTabClick($event)">
			<TabPanel>
				<template #header>
					<i class="pi pi-calendar p-mr-2"></i>
					<span>Por Vencimiento</span>
				</template>				
				<DataTable :value="ResumenData" v-model:expandedRows="expandedRows" dataKey="id" responsiveLayout="scroll" class="p-datatable-sm"
				filterDisplay="menu" :loading="loading">
					<template #header>
						<div class="table-header-container">
							<span class="p-input-icon-left">
								<i class="pi pi-search" />
								<InputText v-model="filters['global'].value" placeholder="Buscar..." class="p-mr-1"/>
							</span>					
							<Button icon="pi pi-plus" label="Expandir Todos" @click="expandAll" class="p-mr-2" />
							<Button icon="pi pi-minus" label="Colapsar Todos" @click="collapseAll" class="p-mr-2" />
							<Button label="Exportar" icon="pi pi-file-excel" class="p-button-success p-mr-1" @click="exportarXLSPorVencimiento"/>								
						</div>
					</template>
					<Column :expander="true" headerStyle="width: 3rem" />
					<Column field="titulo" header="Rango">
						<template #body="slotProps">
							<div :class="claseResumen(slotProps.data)" >
								{{slotProps.data.titulo}}
							</div>
						</template>			
					</Column>
					<Column field="moneda" header="Moneda">
						<template #body="slotProps">
							<div :class="claseResumen(slotProps.data)" >
								{{slotProps.data.moneda}}
							</div>
						</template>				
					</Column>            
					<Column field="num_docs" header="Docs">
						<template #body="slotProps">
							<div :class="claseResumen(slotProps.data)" >
								{{slotProps.data.num_docs}}
							</div>
						</template>				
					</Column>
					<Column field="total" header="Total" headerClass="p-d-flex p-jc-end">
					<template #body="slotProps">
						<div :class="claseResumen(slotProps.data)">
							<div class="p-d-flex p-jc-end">
								<div>{{formatCurrency(slotProps.data.total)}}</div>
							</div>
						</div>
					</template>			
					</Column>
					<template #expansion="slotProps">
						<div class="orders-subtable">
							<h5>Documentos {{slotProps.data.titulo}}</h5>
							<DataTable :value="slotProps.data.docs" responsiveLayout="scroll" class="p-datatable-sm"  v-model:selection="setTrx" selectionMode="single" :filters="filters" :globalFilterFields="['ctahabiente']">
								<Column field="ctahabiente" :header="tituloCol" style="flex: 0 0 8rem"></Column>
								<Column field="fecha_pago" header="Fecha de Pago" style="flex: 0 0 2rem"></Column>
								<Column field="documento" header="No.Docto" style="flex: 0 0 2rem">
									<template #body="slotProps">
										{{slotProps.data.serie}}-{{formatDocument(slotProps.data.no_docto)}}
									</template>
								</Column>
								<Column field="doc_nombre" header="Emitido a" style="flex: 0 0 4rem"></Column>
								<Column field="FEL_doc" header="FEL" style="flex: 0 0 4rem"></Column>
								<Column field="saldo" header="Saldo" headerClass="p-d-flex p-jc-end">					
									<template #body="slotProps">
										<div class="p-d-flex p-jc-end">
											<div>{{formatCurrency(slotProps.data.saldo)}}</div>
										</div>
									</template>
								</Column>
								<Column headerStyle="width:4rem">
									<template #body="slotProps">
										<Button icon="pi pi-search" @click="verTrx(slotProps.data)"/>
									</template>
								</Column>
							</DataTable>
						</div>
					</template>
				</DataTable>
			</TabPanel>
			<TabPanel>
				<template #header>
					<i :class='iconoCtaHabiente'></i>
					<span>{{ctaHabienteTitulo}}</span>
				</template>				
				<DataTable :value="CtaHabientesSaldo" dataKey="id_cta_habiente_info" :key="CtaHabientesSaldoKeyUpdate" :paginator="true" :rows="10" :filters="filtersCtahabiente" :globalFilterFields="['cta_habiente']"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}"  :loading="loading"
							@filter="filter">
					<template #header>
						<div class="table-header-container">
							<span class="p-input-icon-left">
								<i class="pi pi-search" />
								<InputText v-model="filtersCtahabiente['global'].value" placeholder="Buscar..." class="p-mr-1"/>
							</span>																			
							<Button icon="pi pi-refresh" label="Refrescar" @click="cargarResumeCtahabientes" class="p-mr-1" />	
							<Button label="Enviar" icon="pi pi-envelope" class="p-button-secondary p-mr-1" @click="envioNotificacionesDlg=true" :disabled="!puedeEnviarEmails"/>								
							<Button label="Exportar" icon="pi pi-file-excel" class="p-button-success p-mr-1" @click="exportarXLSResumenCtaHabiente"/>
							<Button v-if="(plantillaMoraAsignada!='')&&(puedeGenerarCargosMora)" label="Generar Cargos Por Mora" icon="pi pi-cog" class="p-button-warning p-mr-1" @click="calcularCargosMora"/>							
							<div v-if="(plantillaMoraAsignada=='')&&(puedeGenerarCargosMora)" class="p-error">No hay un plan definido para calculo de mora!</div>
						</div>							
					</template>
					<Column field="cta_habiente" :header="tituloCol">
						<template #body="slotProps">
							<div>
								{{slotProps.data.cta_habiente}}
							</div>
						</template>			
					</Column>     
					<Column field="saldo" header="Saldo" headerClass="p-d-flex p-jc-end">
						<template #body="slotProps">
							<div>
								<div class="p-d-flex p-jc-end">
									<div>{{formatCurrency(slotProps.data.saldo)}}</div>
								</div>
							</div>
						</template>			
					</Column>
					<Column field="id_cta_habiente_info">
						<template #body="slotProps">
							<div>
								<Button class="p-button-success p-button-rounded" icon="pi pi-file-pdf"  @click="getResumenSaldosRpt(slotProps.data)"/>									
							</div>
						</template>			
					</Column>		
					<ColumnGroup type="footer">
						<Row>
							<Column footer="Saldo Total:" :colspan="2" footerStyle="text-align:right" />
							<Column :footer="formatCurrency(totalVencidoCtaHabientes)" footerStyle="text-align:left"/>			
						</Row>
					</ColumnGroup>							
					<!-- <Column headerStyle="width: 3rem">
						<template #body="slotProps">							
							<Button text="Ver" icon="pi pi-eye" class="p-button-rounded p-button-success p-mr-2" @click="mostrarDetalleSaldo(slotProps.data)" />							
						</template>
					</Column>							 -->
				</DataTable>
			</TabPanel>
		</TabView>
			</div>
		</div>

		<Dialog v-model:visible="envioNotificacionesDlg" :style="{width: '1150px', height: '600px'}"  :showHeader="true" :modal="true" >
			<template #header>
				<div class="p-fluid">											
					<div class="p-fluid p-m-1">
						<h4 >
						Envio de Resumen de Saldos por Email
						</h4>				
					</div>				
				</div>
			</template>
			<div :style="{height: '425px'}">
				<DataTable ref="dt" :value="CtaHabientesSaldo"  class="p-datatable-customers" v-model:selection="selectedCtahabientes" dataKey="id_cta_habiente_info" :paginator="true" :rows="10" :filters="filters"
								paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
								currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}" :loading="loading">
					<Column selectionMode="multiple" headerStyle="width: 3rem"/>
					<Column field="cta_habiente" :header="tituloCol" :sortable="true"/>
				</DataTable>
			</div>
			<template #footer>
				<div class="p-field p-col">
					<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="envioNotificacionesDlg=false"/>
					<Button label="Enviar" icon="pi pi-send" class="p-button-text" @click="enviarACtahabienteSeleccionados" :disabled="!enviarEmails"/>
				</div>
			</template>
		</Dialog>

		<Dialog v-model:visible="enviandoEmailsDlg" :showHeader="true" :modal="true" :closable="false">			
			<div class="flex align-content-center flex-wrap card-container" :style="{width: '500px', height: '250'}">
				<div class="p-d-flex p-ai-center p-jc-center">
					<i class="pi pi-send p-m-2" style="font-size: 2rem"></i>
				</div>
				<div class="flex align-items-center justify-content-center mt-5">				
				<ProgressBar :value="secuencia">										
					<div class="font-light">
					Enviando {{secuencia}} de {{selectedCtahabientes.length}}
					</div>
				</ProgressBar>
				</div>
			</div>
		</Dialog>

		<Dialog v-model:visible="generarCargosMoraDlg" :style="{width: '1150px', height: '600px'}"  :showHeader="true" :modal="true" >
			<template #header>
				<div class="p-fluid">											
					<div class="p-fluid p-m-1">
						<h4 >
						Generar Cargos por Mora a los siguientes {{tituloCol}}s
						</h4>				
					</div>				
				</div>
			</template>
			<div :style="{height: '425px'}">
				<DataTable ref="dt" :value="CargosPorMora"  class="p-datatable-customers" v-model:selection="selectedCargos" dataKey="id_movimiento" :paginator="true" :rows="10" :filters="filters"
								paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
								currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}" :loading="loading">
					<Column selectionMode="multiple" headerStyle="width: 3rem"/>
					<Column field="cta_habiente" :header="tituloCol" :sortable="true"/>
					<Column field="referencia" header="Referencia" />
					<Column field="doc_emision" header="Documento"/>                                      
					<Column field="total" header="Total"> 
						<template #body="slotProps">					
							<div class="p-d-flex p-jc-end">
								<div>{{formatCurrency(slotProps.data.total)}}</div>
							</div>   					
						</template>
					</Column>	
				</DataTable>
			</div>
			<template #footer>
				<div class="p-field p-col">
					<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="generarCargosMoraDlg=false"/>
					<Button label="Generar" icon="pi pi-cog" class="p-button-text" @click="generarCargosMora" :disabled="totalTrxAEmitir.length==0"/>
				</div>
			</template>
		</Dialog>


		<Dialog v-model:visible="procesandoDlg" :showHeader="true" :modal="true" :closable="false">			
			<div class="flex align-content-center flex-wrap card-container" :style="{width: '500px', height: '250'}">
				<div class="p-d-flex p-ai-center p-jc-center">
					<!-- <i class="pi pi-send p-m-2" style="font-size: 2rem"></i> -->
					<img src="/assets/layout/images/document.gif" width="64" height="64"/>
				</div>
				<div class="flex align-items-center justify-content-center mt-5">				
				<ProgressBar :value="secuencia">										
					<div class="font-light">
					Emitiendo {{secuencia}} de {{selectedCargos.length}}...
					</div>
				</ProgressBar>
				</div>
			</div>
		</Dialog>		
	</div>

</template>

<script>
import GrlService from '../service/grl.service';
import CxcService from '../service/cxc.service';
import {FilterMatchMode} from 'primevue/api';
import MdsService from '../service/mds.service';
import { CURRENT_APP_MODE, MKT_APP_MODE } from '../service/constants';
import { read, utils, writeFileXLSX } from 'xlsx';

export default {
	setup() {
		const rowClass = (data) => {
            return data.anulado === 'S' ? 'row-anulada': null;
        };

		return { rowClass }
	},	
	data() {
		return {
			appMode: CURRENT_APP_MODE,
			tituloCol: CURRENT_APP_MODE == MKT_APP_MODE ? 'Ctahabiente' : 'Inmueble',
			activeTabIndex: 0,
			trxs: null,
			lkpMonedas: this.$store.state.auth.currentAppCtx.Monedas,
			setTrx: null,
			ResumenData: [],
			expandedRows: [],
			filteredData: [],
			CtaHabientesSaldo: null,
			CtaHabientesSaldoKeyUpdate: 0,
			CargosPorMora: null,
			iconoCtaHabiente: 'pi pi-table p-mr-2',
			ctaHabienteTitulo: CURRENT_APP_MODE == 0 ? 'Por Ctahabiente' : 'Por Inmueble',
			lkpSucursales: this.$store.state.auth.currentAppCtx.Sucursales,			
			lkpTiposProducto: null,
			id_moneda: this.$store.state.auth.currentAppCtx.monedaBase.id_moneda,
			loading: false,
			filters: {'global': {value: null, matchMode: FilterMatchMode.CONTAINS}},
			filtersCtahabiente: {'global': {value: null, matchMode: FilterMatchMode.CONTAINS}},
			submitted: false,
			selectedCtahabientes: null,
			selectedCargos: null,
			envioNotificacionesDlg: false,
			generarCargosMoraDlg: false,
			generandoCargosMoraDlg: false,
			puedeGenerarCargosMora: (this.$store.getters['auth/tieneAcceso']('CC_MORA_EMISION')),
			plantillaMoraAsignada: (this.$store.getters['auth/parametroValor']('MORA_PLANTILLA')),
			enviandoEmailsDlg: false,
			secuencia: 1,
			procesandoDlg: false,			
		}
	},
	mounted() {
		this.buscar();
	},
	computed: {
		puedeEnviarEmails() {
			if(this.CtaHabientesSaldo)
			{
				return this.CtaHabientesSaldo.length > 0;
			} else {
				return false;
			}
		},
		enviarEmails() {
			if(this.selectedCtahabientes)
			{
				return this.selectedCtahabientes.length > 0;
			} else {
				return false;
			}
		},
		totalTrxAEmitir()
		{
			if(this.selectedCargos)
			{
				return this.selectedCargos.length > 0;
			} else {
				return false;
			}
		},
		totalVencidoCtaHabientes() {
			var totalAcumulado = 0.0;

			var docs = null;
			
			if(this.filteredData) {
				docs = this.filteredData;
			} else {
				docs = this.CtaHabientesSaldo;	
			}

			if(docs) {
				for(let doc of docs) {
					totalAcumulado += doc.saldo;
				}
			}
		
			return totalAcumulado;
		}
	},
	methods: {
		onTabClick(event)
		{
			if((event.index == 0) && (!this.ResumenData)) {
				this.buscar();
			} else if((event.index == 1) && (!this.CtaHabientesSaldo)) {
				this.cargarResumeCtahabientes();
			}
		},		
		refrescarVista(){
			if(this.activeTabIndex==0) {
				this.buscar();
				this.CtaHabientesSaldo = null;
			} else {
				this.cargarResumeCtahabientes();
				this.ResumenData = null;
			}
		},		
		buscar(){
			this.loading = true;

			CxcService.getResumenSaldos(this.$store.state.auth.currentAppCtx).then(data => {					
				this.loading = false;				
				this.ResumenData = data;									
			}).catch(err => {
				this.loading = false;
				this.ResumenData = []
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})				
				}
			);	
		},
		getResumenSaldosRpt(Ctahabiente)
		{
			this.loading = true;

			let Data = {
				reporte: `cxcResumenSaldos`,
				params: [					
					{ParamName: "idCtaHabienteInfo", Valor: Ctahabiente.id_cta_habiente_info},
					{ParamName: "idEmpresa", Valor: this.$store.state.auth.currentAppCtx.id_referencia},
					{ParamName: "idMoneda", Valor: this.id_moneda},				
				]
			}

			GrlService.GenerarRpt(this.$store.state.auth.currentAppCtx, Data).then(
				response => {
					//Create a Blob from the PDF Stream
					this.loading = false;
					const file = new Blob(
					[response.data], 
					{type: 'application/pdf'});
					//Build a URL from the file
					const fileURL = URL.createObjectURL(file);	

					var link = document.createElement('a');
					link.href = fileURL;
					link.download = `ResumenSaldos(${Ctahabiente.cta_habiente}).pdf`;
					link.dispatchEvent(new MouseEvent('click'));
				}				
			).catch(
				data => {
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data})
				}
			)					
		},
		async cargarResumeCtahabientes(){
			this.loading = true;
			try {
				this.CtaHabientesSaldo = await CxcService.getResumenSaldosCtaHabiente(this.$store.state.auth.currentAppCtx, this.id_moneda);
				this.CtaHabientesSaldoKeyUpdate++;
				this.loading = false;
			} catch (error)
			{
				this.loading = false;				
				this.CtaHabientesSaldo = []
				this.CtaHabientesSaldoKeyUpdate++;
				this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000})								
			}		
		},
		filter(e){
			this.filteredData = e.filteredValue;
		},		
		async exportarXLSResumenCtaHabiente()
		{
			this.loading = true;
			try {
				var rows;
				if(this.appMode == 0) {
					rows = this.CtaHabientesSaldo.map(
						row => ({							
							"CtaHabiente": row.cta_habiente,
							"Saldo": row.saldo
						})
					);
				} else {
					rows = this.CtaHabientesSaldo.map(
						row => ({							
							"Inmueble": row.cta_habiente,
							"Saldo": row.saldo
						})
					);					
				}

  				const ws = utils.json_to_sheet(rows);
				var wb = utils.book_new();
				utils.book_append_sheet(wb, ws, `${this.id_moneda} - Saldos Vencidos`);
				writeFileXLSX(wb, "ResumenSaldosPorInmueble.xlsx");

				this.loading = false;
			} catch (error)
			{
				this.loading = false;				
				this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000})								
			}	
		},		
		async exportarXLSPorVencimiento()
		{
			this.loading = true;
			try {
				var rows;

				if(this.appMode==0) {
					rows = this.ResumenData.map(
						row => {
							return row.docs.map( doc => (
								{
								"Rango": row.titulo,
								"Ctahabiente": doc.ctahabiente,
								"Fecha de Pago": doc.fecha_pago,
								"No Docto": doc.documento,
								"FEL": doc.FEL_doc,
								"Saldo": doc.saldo
								}
							))						
						}					
					).flat();
				} else {
					rows = this.ResumenData.map(
						row => {
							return row.docs.map( doc => (
								{
								"Rango": row.titulo,
								"Inmueble": doc.ctahabiente,
								"Fecha de Pago": doc.fecha_pago,
								"No Docto": doc.documento,
								"FEL": doc.FEL_doc,
								"Saldo": doc.saldo
								}
							))						
						}					
					).flat();
				}
  				const ws = utils.json_to_sheet(rows);
				var wb = utils.book_new();
				utils.book_append_sheet(wb, ws, `${this.id_moneda} - Por Vencimiento`);
				writeFileXLSX(wb, "SaldosPorVencimiento.xlsx");

				this.loading = false;
			} catch (error)
			{
				this.loading = false;				
				this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000})								
			}	
		},
		async enviarACtahabienteSeleccionados()
		{
			this.envioNotificacionesDlg	= false;
			try
			{
				this.enviandoEmailsDlg = true;
				this.secuencia = 1;
				for(var i = 0; i < this.selectedCtahabientes.length; i++)
				{				
					await MdsService.enviarEmailResumenSaldos(this.$store.state.auth.currentAppCtx,
						{
							id_empresa: `${this.$store.state.auth.currentAppCtx.id_referencia}`,
							id_moneda: `${this.id_moneda}`,
							id_cta_habiente_info: `${this.selectedCtahabientes[i].id_cta_habiente_info}`
						},
						{}
					)
					this.secuencia++;
				}
				this.secuencia = 1;
				this.enviandoEmailsDlg = false;
			}
			catch(error) {
				console.log(error);
			}
		},
		async calcularCargosMora() {
			this.loading = true;
			try {
				let ctahabientes = this.CtaHabientesSaldo.map(cta => {
					return {
						id_cta_habiente_info: cta.id_cta_habiente_info,
					}
				});

				let condiciones = {
					fecha: new Date(),
					fecha_pago: new Date(),
					procesar: false
				};

				this.CargosPorMora = await CxcService.getCaculoPreliminarMora(this.$store.state.auth.currentAppCtx, condiciones, ctahabientes)
				this.loading = false;
				this.generarCargosMoraDlg = true;
			} catch (err)
			{
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})
			}			
		},
		async generarCargosMora() {
			let i;
			let cargo;
			var index = -1;
			var data;

			this.secuencia = 1;
			this.generarCargosMoraDlg = false;
			this.procesandoDlg = true;

			let condiciones = {
				fecha: new Date(),
				fecha_pago: new Date(),
				procesar: true
			};

			var ctahabiente;
			try 
			{
				for (i = 0; i < this.selectedCargos.length; i++) {
					cargo = this.selectedCargos[i];
					try {

						ctahabiente = [{"id_cta_habiente_info": cargo.id_cta_habiente_info}];

						data = await CxcService.getCaculoPreliminarMora(this.$store.state.auth.currentAppCtx, condiciones, ctahabiente);

						if(data[0].msg_error != '')
							console.log(`... error [${data[0].msg_error}]`);													
					} catch(error) {
						cargo.msg_error = error;
						this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000})
					}
					this.secuencia++;
				}	
			} finally {
				this.selectedCargos = [];
				this.procesandoDlg = false;
				this.secuencia = 0;
			}
		},		
		mostrarDetalleSaldo(CtaHabiente) {
			console.log(JSON.stringify(CtaHabiente));
		},
		verTrx(trx) {
			this.$router.push({name: 'trx', params: {tipo: trx.id_movimiento_tipo_cat, id: trx.id_movimiento}});			
		},		
        claseResumen(data) {
            return [
                {
                    'corriente': data.id === "0",
                    'a_15':  data.id === "15",
                    'a_30':  data.id === "30",
					'mas_30':  data.id === "30+"					
                 }
            ];
        },	
        expandAll() {
            this.expandedRows = this.ResumenData.filter(p => p.id);
        },
        collapseAll() {
            this.expandedRows = null;
        },
		formatCurrency(value) {
			if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: this.id_moneda});
			return;
		},
		formatDocument(Docto)
		{
			return Docto.toString().padStart(6, '0');
		},
		descargarPDF(movimiento) {	
			CxcService.getFormatoImpresionTrx(this.$store.state.auth.currentAppCtx, movimiento, false).then(
				response => {
					//Create a Blob from the PDF Stream
					const file = new Blob(
					[response.data], 
					{type: 'application/pdf'});
					//Build a URL from the file
					const fileURL = URL.createObjectURL(file);	

					var link = document.createElement('a');
					link.href = fileURL;
					link.download = `${movimiento.movimiento_tipo} ${movimiento.serie}-${movimiento.no_docto}.pdf`;
					link.dispatchEvent(new MouseEvent('click'));

					//Open the URL on new Window
					//window.open(fileURL);
				}				
			).catch(
				data => {
					this.anularDialog = false;
					this.mov = {};
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		}
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-corriente {
		background: #C8E6C9;
		color: #256029;
	}
	&.status-a_15 {
		background: #FFCDD2;
		color: #f7df0a;
	}

	&.status-a_30 {
		background: #FEEDAF;
		color: #f18520;
	}

	&.status-mas_30{
		background: #FFCDD2;
		color: #C63737;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}

::v-deep(.row-anulada) {
    background-color: rgba(0,0,0,.15) !important;
}

.vencido {
    font-weight: 700;
    color: red;
}

.corriente {
	font-weight: 700;
	color: green;
}

.a_15 {
	font-weight: 700;
	color: #f7df0a;
}

.a_30 {
	font-weight: 700;
	color: #f18520;
}

.mas_30{
	font-weight: 700;
	color: red;
}

</style>    

